import api from "./api";
import TokenService from "./token-service";

class AuthServices {
  login(username, password, role) {
    let type = "beta";
    if (role === "admin") {
      type = "alpha";
    }
    return api
      .post(
        "rest/v1/" + role + "/authenticate",
        JSON.stringify({
          username,
          password,
        })
      )
      .then((response) => {
        console.log("res login", response);
        TokenService.setUser(response.data, role);

        return response.data;
      });
  }

  logout(role) {
    TokenService.removeUser(role);
  }

  register(
    name,
    username,
    email,
    password,
    mobileNo,
    initialBalance,
    commission,
    enterPassword,
    role,
    update
  ) {
    console.log("register user 2", role, update);
    if (!update) {
      let tailURL = "alpha/addAgent";
      if (role === "agent") {
        tailURL = "beta/addUser";
      }
      console.log("add", tailURL);
      return api
        .post(tailURL, {
          name,
          email,
          username,
          password,
          mobileNo,
          initialBalance,
          commission,
        })
        .then((response) => {
          return response;
        });
    } else {
      let tailURL = "alpha/updateAgent";
      if (role === "agent") {
        tailURL = "beta/updateUser";

        return api
          .post(tailURL, {
            name,
            username,
            password,
            mobileNo,
            initialBalance,
            enterPassword,
          })
          .then((response) => {
            return response;
          });
      } else {
        return api
          .post(tailURL, {
            name,
            username,
            password,
            mobileNo,
            initialBalance,
            commission,
            enterPassword,
          })
          .then((response) => {
            return response;
          });
      }
    }
  }

  deposit(agentName, balance, password, role) {
    let tailURL = "alpha/depositAmount";
    if (role === "agent") {
      tailURL = "beta/depositAmount";
    }
    console.log("deposit", tailURL);
    return api
      .post(tailURL, {
        agentName,
        balance,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  withdraw(agentName, balance, password, role) {
    let tailURL = "alpha/withdrawAmount";
    if (role === "agent") {
      tailURL = "beta/withdrawAmount";
    }
    console.log("withdraw", tailURL);
    return api
      .post(tailURL, {
        agentName,
        balance,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  changePassword(oldPassword, newPassword, userName, role) {
    return api
      .post("rest/v1/" + role + "/changePassword", {
        oldPassword,
        newPassword,
        userName,
      })
      .then((response) => {
        console.log("cp resp", response);
        TokenService.setUser(response.data, role);

        return response;
      });
  }

  changePasswordProfile(oldPassword, newPassword, role) {
    let tailURL = "alpha/changeSelfPassword";
    if (role === "agent") {
      tailURL = "beta/changeSelfPassword";
    }
    return api
      .post(tailURL, {
        oldPassword,
        newPassword,
      })
      .then((response) => {
        console.log("cp profile resp", response);
        // TokenService.setUser(response.data, role);

        return response;
      });
  }

  block = (userName, accountStatus, password, role) => {
    let tailURL = "alpha/blockAgent";
    if (accountStatus === "BLOCKED") {
      tailURL = "alpha/unblockAgent";
      if (role === "agent") {
        tailURL = "beta/unblockUser";
      }
    } else {
      if (role === "agent") {
        tailURL = "beta/blockUser";
      }
    }

    return api
      .post(tailURL, {
        userName,
        password,
      })
      .then((response) => {
        console.log("block resp", response);
        return response;
      });
  };

  overrideResult = (gameId, winner) => {
    const user = TokenService.getUser("admin");
    const adminName = user.userName;
    const tailURL = "alpha/override/result";

    return api
      .post(tailURL, {
        gameId,
        adminName,
        winner,
      })
      .then((response) => {
        console.log("block resp", response);
        return response;
      });
  };

  settle(userName, upiId, amount, role) {
    let tailURL = "alpha/settleTransaction";
    if (role === "agent") {
      tailURL = "beta/settleTransaction";
    }
    console.log("settle", tailURL);
    return api
      .post(tailURL, {
        userName,
        upiId,
        amount,
      })
      .then((response) => {
        return response;
      });
  }

  approveRecharge(id, rechargeState, role) {
    let tailURL = "alpha/updateRechargeRequest";
    if (role === "agent") {
      tailURL = "beta/updateRechargeRequest";
    }
    console.log("approve recharge", tailURL);
    return api
      .post(tailURL, {
        id,
        rechargeState,
      })
      .then((response) => {
        return response;
      });
  }

  approveWithdraw(id, rechargeState, role, transactionNumber, message) {
    let tailURL = "alpha/updateWithdrawRequest";
    if (role === "agent") {
      tailURL = "beta/updateWithdrawRequest";
    }
    console.log("approve withdraw", tailURL);
    return api
      .post(tailURL, {
        id,
        rechargeState,
        transactionNumber,
        message,
      })
      .then((response) => {
        return response;
      });
  }

  addMyUpi(name, upi, description, password, image, role) {
    let tailURL = "alpha/addUpi";
    if (role === "agent") {
      tailURL = "beta/addUpi";
    }
    console.log("add upi", tailURL);
    return api
      .post(tailURL, {
        name,
        upi,
        description,
        password,
        image,
      })
      .then((response) => {
        return response;
      });
  }

  registerReferred(name, userName, agentCode, password, tailURL) {
    console.log("add upi", tailURL);
    return api
      .post(tailURL, {
        name,
        userName,
        agentCode,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  deleteMyUpi(id, password, role) {
    let tailURL = "alpha/deleteUpi";
    if (role === "agent") {
      tailURL = "beta/deleteUpi";
    }
    console.log("delete upi", tailURL);
    return api
      .post(tailURL, {
        id,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  notifyAllUsers(agentName, message, password, emailUser, role) {
    let tailURL = "alpha/notifyAllUsers";
    if (role === "agent") {
      tailURL = "beta/notifyAllUsers";
    }
    console.log("notify all users", tailURL);
    return api
      .post(tailURL, {
        agentName,
        message,
        emailUser,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  notifyUser(agentName, userName, message, password, emailUser, role) {
    let tailURL = "alpha/notifyUser";
    if (role === "agent") {
      tailURL = "beta/notifyUser";
    }
    console.log("notify user", tailURL);
    return api
      .post(tailURL, {
        agentName,
        userName,
        message,
        emailUser,
        password,
      })
      .then((response) => {
        return response;
      });
  }

  //   getCurrentUser() {
  //     return TokenService.getUser();
  //   }
}

export default new AuthServices();

